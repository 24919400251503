import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import Sidebar from "./components/Sidebar";
import AppRoutes from "./AppRoutes";
import { Box } from "@mui/material";
import theme from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box component="main">
          <AppRoutes />
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;
