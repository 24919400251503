import React from "react";
import { Container, Typography } from "@mui/material";

const HomePage = () => {
  return (
    <Container>
      <Typography variant="h1">essa é minha página</Typography>
    </Container>
  );
};

// Aqui estou adicionando alguns estilos básicos

export default HomePage;
